:root {
	--color-text-green: #00af89;
	--color-text-red: #ff3b3b;

	--color-text-grey: #464f58;
	--color-text-grey-2: #6d7883;
	--color-text-grey-3: #000;
	--color-text-grey-4: #a7b0b8;
	--color-text-grey-5: #464f58;
	--color-text-grey-6: #6d6d70;
	--color-text-grey-10: #000;
	--color-text-grey-7: #c2c8ce;
	--color-text-grey-8: #000;
	--color-text-grey-9: #000;
	--color-success-text-light: #00af89;
	--color-success-background: #f2fcf6;
	--color-secondary-dark: #feea9a;
	--color-selected-secondary-dark: #fffae6;
	--color-select-background-selected: #ffffff;
	--bg-secondary: #fff;
	--border-main: #f1f2f4;
	--border-secondary: #f7f7f7;
	--border-tertiary: #e5e7ea;
	--text-main: #000;
	--color-btn-border-disabled: #adadad;
	--color-btn-background-disabled: #fef8de;
	--color-text-secondary: #4d4d4d;
	--color-paragraph-dark: #a7b0b8;
	--color-box-background: #ffffff;
	--color-box-background-2: #f7f7f7;
	--color-box-background-3: #f7f7f7;
	--color-box-background-4: #131b22;
	--color-box-background-5: #1f272d;
	--color-box-background-6: #e5e7ea;
	--color-box-background-7: #fff;
	--color-box-background-8: #fff;
	--color-box-background-10: var(--color-secondary-background-light-base);
	--color-box-background-9: #f7f7f7;
	--color-box-background-10: #fff;
	--color-box-background-11: #f7f7f7;
	--color-box-background-12: #fff;
	--color-box-background-13: #f7f7f7;
	--color-box-background-14: #f2f2f2;

	--color-black-white: #000;
	--color-border: #e5e7ea;
	--color-border-2: #f7f7f7;
	--color-border-3: #000;
	--color-border-4: #c2c8ce;
	--color-text-light: #63707d;
	--color-bg-main: #fff;
	--color-border-5: #f1f2f4;
	--color-border-6: #e5e7ea;
	--color-border-7: #e5e7ea;
	--color-border-8: #c2c8ce;
	--color-border-9: #e5e7ea;
	--color-border-14: #e5e5ea;
	--color-border-10: #e1e3e5; /*   --color-primary-separator-light */
	--color-border-11: #000;
	--color-border-12: #e1e3e5;
	--color-border-13: #bebec0;
}

[data-theme='dark'] {
	--color-text-green: #7affe2;
	--color-text-grey: #a7b0b8;
	--color-text-grey-2: #a7b0b8;
	--color-text-grey-3: #e5e7ea;
	--color-text-grey-4: #63707d;

	--color-text-grey-5: #939393; /* --color-secondary-text-dark*/
	--color-text-grey-6: #939393; /* --color-secondary-text-dark*/
	--color-text-grey-7: #c2c8ce;
	--color-text-grey-8: #a7b0b8;
	--color-text-grey-9: #939393; /*var(--color-secondary-text-dark);*/
	--color-text-red: #f0565a;
	--color-success-background: #1b494b;
	--color-secondary-dark: #413501;
	--color-selected-secondary-dark: #413501;
	--color-select-background-selected: #3d4752;
	--bg-secondary: #2d3339;
	--border-main: #292e33;
	--border-secondary: #63707d;
	--border-tertiary: #464f58;
	--text-main: #fff;
	--color-btn-border-disabled: #676c70;
	--color-btn-background-disabled: #272d33;
	--color-text-secondary: #63707d;
	--color-box-background: #2d3339;
	--color-box-background-2: #2d3339;
	--color-box-background-3: #363d44;
	--color-box-background-4: #363d44;
	--color-box-background-5: #40474e;
	--color-box-background-6: #777a80;
	--color-box-background-7: #1f2327;
	--color-box-background-8: #272d33;
	--color-box-background-9: #3d4752;
	/* --color-box-background-10: var(--color-secondary-background-dark-elevated);  insurance */
	--color-box-background-10: #2c2c2e;
	--color-box-background-11: #181819;
	--color-box-background-12: #1c1c1e; /*--color-primary-background-dark-elevated*/
	--color-box-background-13: #2c2c2e;
	--color-box-background-14: #2c2c2e; /*--color-secondary-background-dark-elevated*/
	--color-black-white: #fff;
	--color-border: #464f58;
	--color-border-2: #464f58;
	--color-border-3: #353e47;
	--color-border-4: #353e47;
	--color-text-light: #c2c8ce;
	--color-bg-main: #181a1b;
	--color-border-5: #63707d;
	--color-border-6: #63707d;
	--color-border-7: #353e47;
	--color-border-8: #63707d;
	--color-border-9: #353e47;
	--color-border-14: #3a3a3c;
	--color-border-10: #38383a; /* --color-primary-separator-dark */
	--color-border-11: #38383a; /* --color-primary-separator-dark */
	--color-border-12: #38383a; /* --color-primary-separator-dark */
	--color-border-13: #464649;

}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Vazirmatn', sans-serif !important;
}

body,
html {
    font-family: 'Vazirmatn', sans-serif !important;
}

p {
    font-size: 1rem;
}

table {
    border-collapse: collapse;
}

body,
html {
    font-family: normal_font;
    /* overflow-x: hidden; */
}

body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    position: relative;
    min-height: 100vh;
}

.green_txt {
    color: var(--color-text-green) !important;
}

.red_txt {
    color: var(--color-text-red) !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

html {
    scroll-behavior: smooth;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

:focus-visible {
    outline: none;
}

a:hover {
    text-decoration: none;
}

article {
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #ebebeb;
}

::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 94, 129);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
    /* Firefox */
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: 0;
}

.aside_item_active {
    background: #ececf1;
}

.mobileNavActive {
    right: 0 !important;
}

.mobileNavActiveLayout {
    opacity: 1 !important;
    pointer-events: unset !important;
}

.openMobileNavCollaps {
    max-height: max-content !important;
}

textarea {
    resize: none;
}

.parallax>use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}


/*Shrinking for mobile*/

@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }
    .content {
        height: 30vh;
    }
}

.container {
    --bs-gutter-x: 2rem !important;
}

@media (min-width: 576px) {
    .row {
        --bs-gutter-x: 1rem !important;
    }
    .container {
        --bs-gutter-x: 0 !important;
    }
}

.hero__title {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    z-index: 1;
}

.cube {
    position: absolute;
    top: 50vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #fd9e0838;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    -webkit-animation: cube 12s ease-in forwards infinite;
    animation: cube 12s ease-in forwards infinite;
}

.cube:nth-child(2n) {
    border-color: #fd9e0838;
}

.cube:nth-child(2) {
    -webkit-animation-delay: 0s;
    animation-delay: 2s;
    left: 25vw;
    top: 10vh;
}

.cube:nth-child(3) {
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
    left: 75vw;
    top: 30vh;
}

.cube:nth-child(4) {
    -webkit-animation-delay: 4s;
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
}

.cube:nth-child(5) {
    -webkit-animation-delay: 6s;
    animation-delay: 8s;
    left: 10vw;
    top: 20vh;
}

.cube:nth-child(6) {
    -webkit-animation-delay: 7s;
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
}

@-webkit-keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

.grecaptcha-badge,
.g-recaptcha {
    display: none;
}

@keyframes airdropAnimation {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@keyframes glowing {
    0% {
        box-shadow: #fcd535 0px 0px 11px 1px;
    }
    50% {
        box-shadow: #fcd535 0px 0px 11px 4px;
    }
    100% {
        box-shadow: #fcd535 0px 0px 11px 1px;
    }
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }
    50%,
    100% {
        left: 100%;
    }
}

@keyframes NFT-Market-SlideIN-LTR {
    0% {
        left: -350px;
    }
    50%,
    100% {
        left: 0;
    }
}

@keyframes Fade-IN {
    0% {
        opacity: 0;
    }
    50%,
    100% {
        opacity: 1;
    }
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }
    50%,
    100% {
        top: 100%;
    }
}

@keyframes glow_animation {
    0% {
        scale: 0.95;
        filter: brightness(1.2);
    }
    50% {
        scale: 1.1;
        filter: brightness(1.9);
    }
    100% {
        scale: 0.95;
        filter: brightness(1.2);
    }
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }
    50%,
    100% {
        right: 100%;
    }
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }
    50%,
    100% {
        bottom: 100%;
    }
}

@keyframes AlertBuzzer {
    0% {
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, 10deg);
    }
    30%,
    50%,
    60% {
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    }
    40% {
        transform: rotate3d(0, 0, 1, 10deg);
    }
    70%,
    80%,
    90%,
    100% {
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes LeftSlideOut {
    0% {
        transform: scale(1) translateX(0);
        opacity: 1;
    }
    100% {
        transform: scale(0) translateX(-100%);
        opacity: 0;
    }
}

@keyframes LeftSlideIn {
    0% {
        transform: translateX(-100%) scale(0);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
}

@keyframes RightSlideIn {
    0% {
        transform: translateX(100%) scale(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }
}

@keyframes RightSlideOut {
    0% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(100%) scale(0);
        opacity: 0;
    }
}

@keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FADE_OUT {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes MODAL_BODY_IN {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

@keyframes MODAL_BODY_OUT {
    0% {
        scale: 1;
    }
    100% {
        scale: 0;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes vertical_bounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(10%);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes lamp_blinker {
    0% {
        background: white;
        box-shadow: none;
    }
    20% {
        background: white;
        box-shadow: none;
    }
    40% {
        background: white;
        box-shadow: none;
    }
    60% {
        background: #fcd535;
        box-shadow: #fcd536 0px 0px 11px 4px;
    }
    80% {
        background: #fcd535;
        box-shadow: #fcd536 0px 0px 11px 4px;
    }
    100% {
        background: #fcd535;
        box-shadow: #fcd536 0px 0px 11px 4px;
    }
}